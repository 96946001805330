import React, { Component } from "react";
import { i18n } from "../translate/i18n";
import { Button } from "bloomer";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import { AlertIcon, CloseCircleIcon, CubeSendIcon } from "mdi-react";
import MaskedInput from "react-maskedinput";
import "../assets/styles/views/curriculos.scss";
import Recaptcha from "react-recaptcha";
import firebase from "../firebase";
import FileUploader from "react-firebase-file-uploader/lib/CustomUploadButton";

class Curriculos extends Component {
  constructor(props) {
    super(props);

    this.verifyCallback = this.verifyCallback.bind(this);

    this.state = {
      name: "",
      area: "",
      email: "",
      phone: "",
      answer: "",
      isValidRecaptcha: false,
      modalIsOpen: false,

      inputCurriculo: `${i18n.t("trabalheConosco.selecioneOArquivo")}`,
      curriculo: "",
      isUploading: false,
      progress: 0,
      curriculoURL: "",
      disabledButton: true,
    };

    this.validator = new SimpleReactValidator({
      messages: {
        default: "Campo obrigatório",
      },
    });
  }

  handleDimissModal = () => {
    this.setState({ modalIsOpen: false });
    this.setState({ name: "" });
    this.setState({ area: "" });
    this.setState({ email: "" });
    this.setState({ phone: "" });
    this.setState({ answer: "" });

    this.setState({ inputCurriculo: "Selecione o arquivo" });
    this.setState({ curriculo: "" });
    this.setState({ isUploading: false });
    this.setState({ progress: 0 });
    this.setState({ curriculoURL: "" });
    this.setState({ disabledButton: true });
  };

  verifyCallback() {
    this.setState({ isValidRecaptcha: true });
  }

  handleFormChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  isValidPhone = (phoneInput) => {
    var phone = phoneInput.replace(/([ ()_-])/g, "");
    if (phone.length === 11) {
      return true;
    }
    return false;
  };

  handleUploadStart = () => {
    this.setState({ isUploading: true, progress: 0 });
    this.setState({ disabledButton: true });
    this.setState({ answer: "" });
  };

  handleProgress = (progress) => {
    this.setState({ progress });
    this.setState({ disabledButton: true });
  };

  handleUploadError = (error) => {
    this.setState({ isUploading: false });
    this.setState({ disabledButton: true });
    this.setState({
      answer: [
        <CloseCircleIcon key="4" />,
        "Ops! Ocorreu algum erro ao enviar seu arquivo. Por favor, tente novamente.",
      ],
    });
    console.error(error);
  };

  handleUploadSuccess = (filename) => {
    this.setState({ curriculo: filename, progress: 100, isUploading: false });
    firebase
      .storage()
      .ref("curriculos")
      .child(filename)
      .getDownloadURL()
      .then((url) => this.setState({ curriculoURL: url }));
    this.setState({ inputCurriculo: "Currículo anexado" });
    this.setState({ disabledButton: false });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ answer: "" });

    if (!this.state.isValidRecaptcha) {
      this.setState({
        answer: [<CloseCircleIcon key="1" />, "Efetue a verificação do robô."],
      });
      return;
    }

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }

    if (!this.isValidPhone(this.state.phone)) {
      this.setState({
        answer: [<AlertIcon key="2" />, "Insira um telefone celular válido."],
      });
      return;
    }

    this.setState({
      answer: [<CubeSendIcon key="3" />, "Enviando sua solicitação..."],
    });

    axios
      .post(`${process.env.REACT_APP_API_CONTACT_URI}/api/users/workwithus`, {
        name: this.state.name,
        area: this.state.area,
        email: this.state.email,
        phone: this.state.phone,
        link: this.state.curriculoURL,
      })
      .then(() => {
        this.setState({ modalIsOpen: true });
      })
      .catch((error) => {
        this.setState({
          answer: [
            <CloseCircleIcon key="4" />,
            "Ops! Ocorreu algum erro ao enviar sua solicitação. Por favor, tente novamente.",
          ],
        });
        console.log(error);
      });
  };

  render() {
    const { modalIsOpen } = this.state;
    const { onLoadCallback, verifyCallback } = this;

    return (
      <div id="curriculos" className="animated fadeInUp">
        <div className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-7">
                <div className="box">
                  <h1>{i18n.t("trabalheConosco.envieSeuCurriculum")}</h1>
                  <h2>{i18n.t("trabalheConosco.preenchaSeusDados")}</h2>
                  <form onSubmit={this.handleSubmit}>
                    <div className="field">
                      <label className="label">
                        {i18n.t("trabalheConosco.nome")}
                      </label>
                      <div className="control">
                        <input
                          name="name"
                          type="text"
                          className="input"
                          value={this.state.name}
                          onChange={this.handleFormChange}
                        />
                        <span>
                          {this.validator.message(
                            "email",
                            this.state.email,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="field">
                      <label className="label">
                        {i18n.t("trabalheConosco.area")}
                      </label>
                      <div className="control">
                        <select
                          name="area"
                          className="input"
                          value={this.state.area}
                          onChange={this.handleFormChange}
                        >
                          <option selected value="">
                            {i18n.t("trabalheConosco.selecioneUmaArea")}
                          </option>
                          <option selected value="Administrativo">
                            {i18n.t("trabalheConosco.administrativo")}
                          </option>
                          <option selected value="Comercial">
                            {i18n.t("trabalheConosco.comercial")}
                          </option>
                          <option selected value="Desenvolvimento">
                            {i18n.t("trabalheConosco.desenvolvimento")}
                          </option>
                          <option selected value="Estágio">
                            {i18n.t("trabalheConosco.estagio")}
                          </option>
                          <option selected value="Suporte">
                            {i18n.t("trabalheConosco.suporte")}
                          </option>
                        </select>
                        <span>
                          {this.validator.message(
                            "area",
                            this.state.area,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="field">
                      <label className="label">
                        {i18n.t("trabalheConosco.email")}
                      </label>
                      <div className="control">
                        <input
                          name="email"
                          type="email"
                          className="input"
                          value={this.state.email}
                          onChange={this.handleFormChange}
                        />
                        <span>
                          {this.validator.message(
                            "email",
                            this.state.email,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="field">
                      <label className="label">
                        {i18n.t("trabalheConosco.celular")}
                      </label>
                      <div className="control">
                        <MaskedInput
                          name="phone"
                          type="text"
                          className="input"
                          value={this.state.phone}
                          onChange={this.handleFormChange}
                          mask="(11) 11111-1111"
                          size="13"
                          placeholder=""
                        />
                        <span>
                          {this.validator.message(
                            "phone",
                            this.state.phone,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>

                    <FileUploader
                      accept=".pdf"
                      name="resume"
                      randomizeFilename
                      ref={(instance) => {
                        this.fileUploader = instance;
                      }}
                      storageRef={firebase.storage().ref("curriculos")}
                      onUploadStart={this.handleUploadStart}
                      onUploadError={this.handleUploadError}
                      onUploadSuccess={this.handleUploadSuccess}
                      onProgress={this.handleProgress}
                      style={{
                        border: "2px solid #00d29b",
                        borderRadius: "2px",
                        color: "#00b686",
                        fontSize: "16px",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        padding: "3px 10px 5px 10px",
                        minWidth: "138px",
                        cursor: "pointer",
                      }}
                    >
                      {this.state.isUploading &&
                        `${this.state.progress}% | Anexando...`}
                      {!this.state.isUploading && this.state.inputCurriculo}
                    </FileUploader>

                    <div className="recaptcha">
                      <Recaptcha
                        sitekey="6LdbZGIUAAAAAIr-e4TTQ2r96Fy2va3yn2sBof7E"
                        render="explicit"
                        onloadCallback={onLoadCallback}
                        verifyCallback={verifyCallback}
                        hl="pt-BR"
                        required
                      />
                    </div>

                    <div className="answer">{this.state.answer}</div>

                    <div className="field">
                      <div className="control">
                        <Button
                          type="submit"
                          disabled={this.state.disabledButton}
                        >
                          {i18n.t("trabalheConosco.enviar")}
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal de currículo enviado com sucesso. */}
        <div className={`modal ${modalIsOpen === true ? "is-active" : null}`}>
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <button
                className="delete"
                aria-label="close"
                onClick={this.handleDimissModal}
              ></button>
            </header>
            <section className="modal-card-body">
              <div className="center-icon">
                <i className="material-icons icon">check_circle_outline</i>
              </div>
              <p>Dados enviados com sucesso.</p>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default Curriculos;
