import React from "react";
import { i18n } from "../../translate/i18n";
import Button from "./../../components/general/Button";

import "../../assets/styles/components/treinamentos/treinamento.scss";

const Treinamento = (props) => {
  return (
    <div id="treinamento">
      <div className="columns is-centered">
        <div className="column is-8 is-11-tablet">
          <div className="init">
            <div className="columns">
              <div className="column">
                <i className="material-icons icon">insert_invitation</i>
                <span className="is-pulled title">
                  {i18n.t("treinamentos.treinamentoAgile.datasETurmasTexto")}
                </span>
                <p
                  className={`description ${props.warning && "is-red"}`}
                  style={{ color: "red" }}
                >
                  {props.habilitado ? props.dataTurma : "Sem turmas definidas."}
                </p>
              </div>
              <div className="column">
                <i className="material-icons icon">attach_money</i>
                <span className="is-pulled title">
                  {i18n.t("treinamentos.treinamentoAgile.investimentoTexto")}
                </span>
                <p className="description">
                  {props.habilitado
                    ? props.investimento
                    : "Sem turmas definidas."}
                </p>
              </div>
            </div>

            <div className="columns">
              <div className="column">
                <i className="material-icons icon">schedule</i>
                <span className="is-pulled title">
                  {i18n.t("treinamentos.treinamentoAgile.horarioTexto")}
                </span>
                <p className="description">
                  {props.habilitado ? props.horario : "Sem turmas definidas."}
                </p>
              </div>
              <div className="column">
                <i className="material-icons icon">error_outline</i>
                <span className="is-pulled title">
                  {i18n.t("treinamentos.treinamentoAgile.informacoesTexto")}
                </span>
                <p className="description">
                  {i18n.t("treinamentos.treinamentoAgile.contatoTexto")}{" "}
                  <a href="mailto:treinamentos@getinfo.net.br" className="mail">
                    treinamentos@getinfo.net.br
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="more">
            <div className="instructor margin-between">
              <i className="material-icons icon">person_outline</i>
              <span className="is-pulled title">
                {i18n.t("treinamentos.treinamentoAgile.instrutorTexto")}
              </span>
              {props.habilitado ? (
                <div className="columns margin">
                  <div className="colum is-1">
                    <img className="image" src={props.instrutorFoto} alt="" />
                  </div>
                  <div className="column no-padding">
                    <h1 className="name">{props.instrutorNome}</h1>
                    <p className="description">{props.instrutorDescricao}</p>
                  </div>
                </div>
              ) : (
                <p className="description margin-turma">
                  Sem instrutor definido.
                </p>
              )}
            </div>

            <div className="margin-between">
              <div className="margin-icon">
                <i className="material-icons icon">adjust</i>
                <span className="is-pulled title">
                  {i18n.t("treinamentos.treinamentoAgile.objetivoTexto")}
                </span>
              </div>
              <p className="description">{props.objetivo}</p>
            </div>

            <div className="margin-between">
              <div className="margin-icon">
                <i className="material-icons icon">done_all</i>
                <span className="is-pulled title">
                  {i18n.t("treinamentos.treinamentoAgile.preRequisitoTexto")}
                </span>
              </div>
              <p className="description">{props.preRequisito}</p>
            </div>

            <div className="margin-between">
              <div className="margin-icon">
                <i className="material-icons icon">supervisor_account</i>
                <span className="is-pulled title">
                  {i18n.t("treinamentos.treinamentoAgile.publicoAlvoTexto")}
                </span>
              </div>
              <p className="description">{props.publicoAlvo}</p>
            </div>

            <div className="margin-between content">
              <div className="margin-icon">
                <i className="material-icons icon">chrome_reader_mode</i>
                <span className="is-pulled title">
                  {i18n.t(
                    "treinamentos.treinamentoAgile.conteudoProgramaticoTexto"
                  )}
                </span>
              </div>
              <p className="description">{props.conteudoProgramatico}</p>
            </div>
          </div>

          <div className="button-align margin-button">
            {props.habilitado ? (
              <Button isLink href="https://pag.ae/7VT2s_513/button">
                {i18n.t("treinamentos.treinamentoAgile.inscrevaSeTexto")}
              </Button>
            ) : (
              <Button disabled>
                {i18n.t(
                  "treinamentos.treinamentoAgile.inscricoesEncerradasTexto"
                )}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Treinamento;
