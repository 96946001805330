import React from "react";
import { i18n } from "../translate/i18n";
import Apple from "mdi-react/AppleIcon";
import "../assets/styles/components/download-app/download-app.scss";

const DownloadApp = (props) => {
  return (
    <div id="download-app">
      <h1 className="has-text-centered">
        {i18n.t("getiTreinamentos.baixeGratuito")}
      </h1>
      <div className="columns margin has-text-centered">
        <div className="column">
          <a href={props.ios} target="_blank" rel="noopener noreferrer">
            <Apple className="apple icone" size={50} />
            <span className="texto">iOS</span>
          </a>
          <br className="is-hidden-tablet" />
          <br className="is-hidden-tablet" />
          <a href={props.android} target="_blank" rel="noopener noreferrer">
            <i className="material-icons icone">android</i>
            <span className="texto">Android</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default DownloadApp;
