import React, { Component } from "react";
import { i18n } from "../../translate/i18n.js";
import {
  Button,
  Container,
  Navbar,
  NavbarBrand,
  NavbarItem,
  NavbarBurger,
  NavbarMenu,
  NavbarEnd,
  NavbarLink,
  NavbarDropdown,
} from "bloomer";
import { NavLink } from "react-router-dom";
import ptBr from "../../assets/images/flags/ptBr.png";
import enUs from "../../assets/images/flags/enUs.png";

// Components
//import Chatbot from "../chatbot/Chatbot";

// Assets
import logo from "./../../assets/images/logo-getinfo2.png";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
    };
    this.onClickNav = this.onClickNav.bind(this);
  }

  onClickNav() {
    this.setState({ isActive: !this.state.isActive });
  }
  handleSetLanguage = (language) => {
    i18n.changeLanguage(language);
    window.location.reload();
  };
  render() {
    return (
      <header>
        <Navbar className="is-fixed-top animated fadeIn">
          <Container>
            <NavbarBrand>
              <NavbarItem>
                <NavLink className="navbar-item" to="/">
                  <img src={logo} alt="" />
                </NavLink>
              </NavbarItem>
              <NavbarBurger
                isActive={this.state.isActive}
                onClick={this.onClickNav}
              />
            </NavbarBrand>
            <NavbarMenu
              isActive={this.state.isActive}
              onClick={this.onClickNav}
            >
              <NavbarEnd>
                <NavbarItem hasDropdown isHoverable>
                  <NavbarLink className="uppercase">
                    {i18n.t("menu.servicos.title")}
                  </NavbarLink>
                  <NavbarDropdown>
                    <NavLink
                      className="navbar-item is-tab"
                      to="/servicos/desenvolvimento"
                    >
                      <i className="material-icons">devices_other</i>
                      {i18n.t("menu.servicos.desenvolvimentoDeSoftwares")}
                    </NavLink>
                    <NavLink
                      className="navbar-item is-tab"
                      to="/servicos/business"
                    >
                      <i className="material-icons">multiline_chart</i>
                      {i18n.t("menu.servicos.bussinessInteligence")}
                    </NavLink>
                    <NavLink
                      className="navbar-item is-tab"
                      to="/servicos/governanca"
                    >
                      <i className="material-icons">business</i>
                      {i18n.t("menu.servicos.governanca")}
                    </NavLink>
                  </NavbarDropdown>
                </NavbarItem>
                <NavLink className="navbar-item uppercase" to="/treinamentos">
                  {i18n.t("menu.treinamentos")}
                </NavLink>
                <NavLink className="navbar-item uppercase" to="/blog">
                  Blog
                </NavLink>
                <NavLink
                  className="navbar-item uppercase"
                  to="/trabalhe-conosco"
                >
                  {i18n.t("menu.trabalheConosco")}
                </NavLink>

                <NavLink className="navbar-item" to="/contato">
                  <Button>{i18n.t("menu.contato")}</Button>
                </NavLink>
                <NavLink
                  className="navbar-item uppercase"
                  to="#"
                  onClick={() => this.handleSetLanguage("en-US")}
                >
                  <img src={enUs} alt="Language English" />
                </NavLink>
                <NavLink
                  className="navbar-item uppercase"
                  to="#"
                  onClick={() => this.handleSetLanguage("pt-BR")}
                >
                  <img src={ptBr} alt="Language Portugues" />
                </NavLink>
              </NavbarEnd>
            </NavbarMenu>
          </Container>
        </Navbar>
        {/*<Chatbot />*/}

        <div style={{ marginBottom: "64px" }}></div>
      </header>
    );
  }
}

export default Header;
