import React, { Component } from "react";
import { i18n } from "../../../translate/i18n";

//Componentes
import AndroidIos from "./../desenvolvimento/AndroidIos";
import Button from "./../../general/Button";
import Card from "./../desenvolvimento/CardComponent";

//images
import treinamentos from "../../../assets/images/desenvolvimento/treinamentos.png";

//CSS
import "../../../assets/styles/components/Servicos/desenvolvimento/getinfoTreinamentos.scss";

class GetinfoTreinamentos extends Component {
  render() {
    return (
      <div id="getinfo-treinamentos" className="animated fadeInUp">
        <Card
          color="black"
          title={i18n.t("getiTreinamentos.title")}
          text={i18n.t("getiTreinamentos.descricao")}
          icons={<AndroidIos />}
          column="6"
          image={treinamentos}
        >
          <Button link="/servicos/desenvolvimento/treinamentos">
            {i18n.t("getiTreinamentos.saibaMais")}
          </Button>
        </Card>
      </div>
    );
  }
}

export default GetinfoTreinamentos;
