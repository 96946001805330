import React, { Component } from "react";
import "../assets/styles/views/blogPost.scss";
import ArtigoDestaque from "./../components/blog/ArtigoDestaque";
import ReactTooltip from "react-tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import axios from "axios";
import { LinkVariantIcon } from "mdi-react";
import { i18n } from "../translate/i18n.js";

class BlogPost extends Component {
  constructor() {
    super();

    this.state = {
      isLoadingPost: true,
      isLoadingPosts: true,
      posts: [],
      post: "",
      countPosts: "",
      disable: true,
    };
  }

  componentDidMount() {
    this.getPost();
    this.getPosts();
    const script = document.createElement("script");

    script.src =
      "https://s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5ece6048a728b031";
    script.async = true;
    var elemento = document.getElementById("blogpost");
    elemento.appendChild(script);
  }
  componentWillUnmount() {
    document.getElementById("at4-share").remove();
  }

  getPost() {
    axios
      .get(
        `${process.env.REACT_APP_API_BLOG_URI}/posts/${this.props.match.params.id}?_embed`
      )
      .then((response) => {
        this.setState({ post: response.data });
        this.setState({ isLoadingPost: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoadingPost: false });
      });
  }

  getPosts() {
    axios
      .get(`${process.env.REACT_APP_API_BLOG_URI}/posts?per_page=3&_embed`)
      .then((response) => {
        // Só traz 2 posts recentes e diferentes do que ta sendo exibido.
        var count = 0;
        for (let i = 0; i < response.data.length; i++) {
          if (count <= 1) {
            if (
              response.data[i].id !== parseInt(this.props.match.params.id, 10)
            ) {
              this.setState({ posts: [...this.state.posts, response.data[i]] });
              count++;
            }
          }
        }

        this.setState({ countPosts: response.data.length });
        this.setState({ isLoadingPosts: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoadingPosts: false });
      });
  }

  render() {
    return (
      <div id="blogpost" className="animated fadeIn">
        <div className="section">
          <div className="container">
            {this.state.isLoadingPost && this.state.isLoadingPosts ? (
              /* CARREGANDO AS NOTICIAS... */
              <div className="columns is-centered card-alert">
                <div className="column is-6">
                  <div className="box">
                    <div className="center-icon">
                      <i className="material-icons icon">more_horiz</i>
                    </div>
                    <p>{i18n.t("blogPost.carregandonoticias")}</p>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                {this.state.post.length === 0 ? (
                  <div className="columns is-centered card-alert animated fadeIn">
                    <div className="column is-6">
                      <div className="box">
                        <div className="center-icon">
                          <i className="material-icons icon">error_outline</i>
                        </div>
                        <p>
                          {i18n.t("blogPost.naofoipossivelcarregarasnoticias")}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="animated fadeIn">
                    {this.state.post._embedded["wp:featuredmedia"] &&
                    this.state.post._embedded["wp:featuredmedia"]["0"]
                      .media_details ? (
                      <div className="columns is-centered">
                        <div className="column is-centered has-text-centered">
                          <img
                            src={
                              this.state.post._embedded["wp:featuredmedia"]["0"]
                                .media_details.sizes.full.source_url
                            }
                            alt="Imagem do post"
                          />
                        </div>
                      </div>
                    ) : null}
                    <div className="columns is-centered">
                      <div className="column is-10 title">
                        <h1>{this.state.post.title.rendered}</h1>
                        <br />
                        <FacebookShareButton
                          url={`https://www.getinfo.net.br${this.props.history.location.pathname}`}
                          title={this.state.post.title.rendered}
                        >
                          <FacebookIcon size={40} round={true} />
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={`https://www.getinfo.net.br${this.props.history.location.pathname}`}
                          title={this.state.post.title.rendered}
                        >
                          <TwitterIcon size={40} round={true} />
                        </TwitterShareButton>
                        <WhatsappShareButton
                          url={`https://www.getinfo.net.br${this.props.history.location.pathname}`}
                          title={this.state.post.title.rendered}
                        >
                          <WhatsappIcon size={40} round={true} />
                        </WhatsappShareButton>
                        <LinkedinShareButton
                          url={`https://www.getinfo.net.br${this.props.history.location.pathname}`}
                          title={this.state.post.title.rendered}
                        >
                          <LinkedinIcon size={40} round={true} />
                        </LinkedinShareButton>

                        <CopyToClipboard
                          text={`https://www.getinfo.net.br${this.props.history.location.pathname}`}
                          onCopy={async () => {
                            await this.setState({ disable: false });
                            ReactTooltip.show(this.fooRef);
                          }}
                        >
                          <span
                            ref={(ref) => (this.fooRef = ref)}
                            data-tip="Link Copiado"
                          >
                            <LinkVariantIcon size={40} />
                          </span>
                        </CopyToClipboard>
                        <ReactTooltip
                          textColor="#fff"
                          borderColor="#fff"
                          backgroundColor="#000000fa"
                          disable={this.state.disable}
                          effect="solid"
                        />
                      </div>
                    </div>
                    <div className="columns is-centered">
                      <div className="column is-10 body">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.state.post.content.rendered,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div>
          {this.state.isLoadingPost && this.state.isLoadingPosts ? (
            <div></div>
          ) : (
            <div>
              {this.state.countPosts > 1 ? (
                <div className="animated fadeIn">
                  <hr />
                  <div className="container">
                    <div className="section">
                      <div className="relacionadas">
                        <div className="columns">
                          <div className="column title-section">
                            <h1>Postagens recentes</h1>
                          </div>
                        </div>
                        <div className="columns is-centered">
                          {this.state.posts.map((post, i) => (
                            <div key={i}>
                              <ArtigoDestaque
                                key={i}
                                src={
                                  post._embedded["wp:featuredmedia"] &&
                                  post._embedded["wp:featuredmedia"]["0"]
                                    .media_details
                                    ? post._embedded["wp:featuredmedia"]["0"]
                                        .media_details.sizes.full.source_url
                                    : null
                                }
                                alt=""
                                title={post.title.rendered}
                                text={post.excerpt.rendered}
                                link={`/blog/${post.slug}/${post.id}/`}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default BlogPost;
