import React from "react";
import { i18n } from "../../translate/i18n";
import { Columns, Column } from "bloomer";
import {
  FacebookBoxIcon,
  LinkedinBoxIcon,
  InstagramIcon,
  TwitterBoxIcon,
} from "mdi-react";

import ScrollAnimation from "react-animate-on-scroll";

// Assets
import getinfo from "../../assets/images/logo-getinfo-1.png";

const Getinfo = (props) => (
  <div id="sobre-nos" className="animated fadeIn">
    <div id="dev-schole-spa-infra-getinfo">
      <div className="section getinfo">
        <div className="container">
          <Columns>
            <Column className="img-column margin-img">
              <ScrollAnimation animateIn="fadeInLeft">
                <img src={getinfo} alt="" />
              </ScrollAnimation>
            </Column>
            <Column className="no-margin-left-right">
              <Column>
                <ScrollAnimation animateIn="fadeInRight">
                  <h1 className="no-margin-title">
                    {i18n.t("home.seuproblema")}
                  </h1>
                </ScrollAnimation>
              </Column>
              <Column>
                <ScrollAnimation animateIn="fadeInRight">
                  <p className="description">{i18n.t("home.criadaEm")}</p>
                </ScrollAnimation>
              </Column>
              <ScrollAnimation animateIn="fadeInRight">
                <Column className="social">
                  <span>
                    <a
                      href="https://www.facebook.com/getinfosolucoes/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FacebookBoxIcon className="facebook icon" />
                    </a>
                  </span>
                  <span>
                    <a
                      href="https://pt.linkedin.com/company/getinfo-solu-es-corporativas/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LinkedinBoxIcon className="linkedin icon" />
                    </a>
                  </span>
                  <span>
                    <a
                      href="https://www.instagram.com/getinfomobile/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <InstagramIcon className="instagram icon" />
                    </a>
                  </span>
                  <span>
                    <a
                      href="https://twitter.com/getinfosolucoes"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <TwitterBoxIcon className="twitter icon" />
                    </a>
                  </span>
                </Column>
              </ScrollAnimation>
            </Column>
          </Columns>
        </div>
      </div>
    </div>
  </div>
);

export default Getinfo;
