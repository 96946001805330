import React, { Component } from "react";
import { i18n } from "../../../../translate/i18n";
//Componentes
import Imagem from "../../../ImagemComponent";
import Info from "../InfoComponent";
import MaisProjetos from "./MaisProjetos";
import Projeto from "./Projeto";
import DownloadApp from "./../../../DownloadApp";

//Imagens
import app from "../../../../assets/images/desenvolvimento/PioNet/pionet6.png";
import avisos from "../../../../assets/images/desenvolvimento/PioNet/pionet5.png";
import horario from "../../../../assets/images/desenvolvimento/PioNet/pionet2.png";
import notas from "../../../../assets/images/desenvolvimento/PioNet/pionet3.png";
import pendencias from "../../../../assets/images/desenvolvimento/PioNet/pionet4.png";
import principal from "../../../../assets/images/desenvolvimento/PioNet/pionet1.png";

import amadeus from "../../../../assets/images/desenvolvimento/MaisProjetos/amadeus.png";
import aminthas from "../../../../assets/images/desenvolvimento/MaisProjetos/aminthas.png";
import pajucara from "../../../../assets/images/desenvolvimento/MaisProjetos/pajucara.png";
import treinamentos from "../../../../assets/images/desenvolvimento/MaisProjetos/treinamentos.png";

//CSS
import "../../../../assets/styles/components/Servicos/desenvolvimento/pioNet.scss";

class PioNet extends Component {
  render() {
    return (
      <div id="pionet" className="animated fadeIn">
        <Info
          title="PioNET"
          color="rgba(0, 0, 0, 0.87)"
          descricao={i18n.t("pionet.descricao")}
          image={principal}
        >
          <Imagem image={app}>
            <span id="pionet">
              <p className="title">{i18n.t("pionet.app")}</p>
              <p className="descricao">{i18n.t("pionet.appDescricao")}</p>
            </span>
          </Imagem>
          <Imagem
            color="rgba(0, 0, 0, 0.87)"
            image={horario}
            imagePosition="left"
          >
            <span id="pionet">
              <p className="title">{i18n.t("pionet.horario")}</p>
              <p className="descricao">{i18n.t("pionet.horarioDescricao")}</p>
            </span>
          </Imagem>
          <Imagem image={notas}>
            <span id="pionet">
              <p className="title">{i18n.t("pionet.notasefaltas")}</p>
              <p className="descricao">
                {i18n.t("pionet.notasefaltasDescricao")}
              </p>
            </span>
          </Imagem>
          <Imagem image={pendencias} imagePosition="left">
            <span id="pionet">
              <p className="title">{i18n.t("pionet.pendencias")}</p>
              <p className="descricao">
                {i18n.t("pionet.pendenciasDescricao")}
              </p>
            </span>
          </Imagem>
          <Imagem image={avisos}>
            <span id="pionet">
              <p className="title">{i18n.t("pionet.avisos")}</p>
              <p className="descricao">{i18n.t("pionet.avisosDescricao")}</p>
            </span>
          </Imagem>
        </Info>
        <MaisProjetos>
          <Projeto
            image={aminthas}
            title="Cartório Aminthas Garcez"
            link="/servicos/desenvolvimento/cartorio-aminthas"
          />
          <Projeto
            image={treinamentos}
            title="Getinfo Treinamentos"
            link="/servicos/desenvolvimento/treinamentos"
          />
          <Projeto
            image={pajucara}
            title="Pajuçara"
            link="/servicos/desenvolvimento/pajucara"
          />
        </MaisProjetos>
      </div>
    );
  }
}

export default PioNet;
