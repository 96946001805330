import React, { Component } from "react";
import { i18n } from "../translate/i18n.js";
import "../assets/styles/pages/business.scss";
import Servico from "../components/Servicos/ServiceComponent";
import FaleConosco from "../components/Servicos/FaleConoscoComponent";
import Imagem from "../components/Servicos/business/ImagensBusiness";
import Qlik from "../assets/images/business/qlik.png";
import bi1 from "../assets/images/business/bi1.png";
import bi2 from "../assets/images/business/bi2.png";
import bi3 from "../assets/images/business/bi3.png";
import marcas from "../assets/images/business/marcas.png";
import banese from "../assets/images/business/banese.png";
import pscom from "../assets/images/business/pscom.png";
import progresso from "../assets/images/business/progresso.png";

class Business extends Component {
  render() {
    return (
      <div id="business">
        <Servico
          icon="multiline_chart"
          title={i18n.t("bussiness.servicos.title")}
          descricao={i18n.t("bussiness.servicos.descricao")}
        >
          <div className="container animated fadeInUp">
            <div id="qlik" className="columns is-centered">
              <div className="column is-2 div-logo">
                <img src={Qlik} alt="" className="logo" />
              </div>
              <div className="column is-4 lista">
                <li>
                  <span>
                    {i18n.t("bussiness.servicos.desenvolvimentoDeIndicadores")}
                  </span>
                </li>
                <li>
                  <span>
                    {i18n.t("bussiness.servicos.analiseDeTendencias")}
                  </span>
                </li>
                <li>
                  <span>{i18n.t("bussiness.servicos.modelagemPreditiva")}</span>
                </li>
                <li>
                  <span>{i18n.t("bussiness.servicos.paineis")}</span>
                </li>
              </div>
            </div>

            <div id="visao-geral">
              <div className="columns is-centered">
                <p className="has-text-centered titulo-section">
                  {i18n.t("bussiness.servicos.visaoGeraldaFerramenta")}
                </p>
              </div>
              {/* Kpi Dashboard */}
              <Imagem
                imagePosition="left"
                title={i18n.t("bussiness.imagem.title")}
                text={i18n.t("bussiness.imagem.text")}
                image={bi1}
              />
              {/* Sales Analisys */}
              <Imagem
                title={i18n.t("bussiness.imagem2.title")}
                text={i18n.t("bussiness.imagem2.text")}
                image={bi2}
              />
              {/* Interfaces de Usuario */}
              <Imagem
                imagePosition="center"
                title={i18n.t("bussiness.imagem3.title")}
                text={i18n.t("bussiness.imagem3.text")}
                column="12"
                image={bi3}
              />
              {/* Integrações com todas as bases de dados */}
              <Imagem
                imagePosition="center"
                title={i18n.t("bussiness.imagem4.title")}
                text={i18n.t("bussiness.imagem4.text")}
                column="4"
                image={marcas}
              />
            </div>
          </div>
        </Servico>
        <hr className="hr-custom" />
        <div id="cases-de-sucesso" className="animated fadeInUp">
          <div className="section">
            <div className="container">
              <p className="has-text-centered titulo-section">
                {i18n.t("bussiness.servico.casosdesucesso")}
              </p>
              <div className="box">
                <div className="columns is-vcentered">
                  <div className="column is-3 has-text-centered img-div">
                    <img src={banese} alt="" />
                  </div>
                  <div className="column is-9 text-div">
                    <p className="text-cases">
                      {i18n.t("bussiness.servico.banese")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="box">
                <div className="columns is-vcentered">
                  <div className="column is-3 has-text-centered img-div">
                    <img src={pscom} alt="" />
                  </div>
                  <div className="column is-9 text-div">
                    <p className="text-cases">
                      {i18n.t("bussiness.servico.pscom")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="box">
                <div className="columns is-vcentered">
                  <div className="column is-3 has-text-centered img-div">
                    <img src={progresso} alt="" />
                  </div>
                  <div className="column is-9 text-div">
                    <p className="text-cases">
                      {i18n.t("bussiness.servico.progresso")}
                      <br />
                      <br />
                      {i18n.t("bussiness.servico.progressoPt2")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />
        <FaleConosco
          textButton={i18n.t("bussiness.servico.faleConosco.textButton")}
        />
      </div>
    );
  }
}

export default Business;
