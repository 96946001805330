import React, { Component } from "react";
import { i18n } from "../../../../translate/i18n";

//Componentes
import Imagem from "../../../ImagemComponent";
import Info from "../InfoComponent";
import MaisProjetos from "./MaisProjetos";
import Projeto from "./Projeto";
import DownloadApp from "./../../../DownloadApp";

//Imagens
import treinamento1 from "../../../../assets/images/treinamentos/treinamentos-2.png";
import treinamento2 from "../../../../assets/images/treinamentos/treinamentos-3.png";
import treinamento3 from "../../../../assets/images/treinamentos/treinamentos-4.png";
import principal from "../../../../assets/images/treinamentos/treinamentos-1.png";

import amadeus from "../../../../assets/images/desenvolvimento/MaisProjetos/amadeus.png";
import aminthas from "../../../../assets/images/desenvolvimento/MaisProjetos/aminthas.png";
import pajucara from "../../../../assets/images/desenvolvimento/MaisProjetos/pajucara.png";
import piox from "../../../../assets/images/desenvolvimento/MaisProjetos/piox.png";

//CSS
import "../../../../assets/styles/components/Servicos/desenvolvimento/getinfoTreinamentos.scss";

class Treinamentos extends Component {
  render() {
    return (
      <div id="getinfo-treinamentos" className="animated fadeIn">
        <Info
          title={i18n.t("getiTreinamentos.title")}
          color="black"
          descricao={i18n.t("getiTreinamentos.descricao")}
          image={principal}
        >
          <Imagem image={treinamento1}>
            <span id="getinfo-treinamentos">
              <p className="title">{i18n.t("getiTreinamentos.crieumaconta")}</p>
              <p className="descricao">
                {i18n.t("getiTreinamentos.crieumacontadescricao")}
              </p>
            </span>
          </Imagem>
          <Imagem
            color="rgba(0, 0, 0, 0.87)"
            image={treinamento2}
            imagePosition="left"
          >
            <span id="getinfo-treinamentos">
              <p className="title">
                {i18n.t("getiTreinamentos.controleotempo")}
              </p>
              <p className="descricao">
                {i18n.t("getiTreinamentos.controleotempodescricao")}
              </p>
            </span>
          </Imagem>
          <Imagem image={treinamento3}>
            <span id="getinfo-treinamentos">
              <p className="title">{i18n.t("getiTreinamentos.simuladoItil")}</p>
              <p className="descricao">
                {i18n.t("getiTreinamentos.simuladoItildescricao")}
                <br />
                <br />
                {i18n.t("getiTreinamentos.baixeagora")}{" "}
              </p>
            </span>
          </Imagem>

          <DownloadApp
            ios="https://itunes.apple.com/br/app/getinfo-treinamentos/id1122808725?mt=8"
            android="https://play.google.com/store/apps/details?id=getinfo.net.br.treinamentos"
          />
        </Info>
        <MaisProjetos>
          <Projeto
            image={aminthas}
            title="Cartório Aminthas Garcez"
            link="/servicos/desenvolvimento/cartorio-aminthas"
          />
          <Projeto
            image={amadeus}
            title="Colégio Amadeus"
            link="/servicos/desenvolvimento/amadeus"
          />
          <Projeto
            image={pajucara}
            title="Pajuçara"
            link="/servicos/desenvolvimento/pajucara"
          />
          <Projeto
            image={piox}
            title="PioNET"
            link="/servicos/desenvolvimento/pionet"
          />
        </MaisProjetos>
      </div>
    );
  }
}

export default Treinamentos;
