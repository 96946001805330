export const text = {
  pt: {
    translations: {
      banner: {
        voceTemaIdeia: 'Você tem a ideia,',
        nosTemosaSolucao: 'nós temos a solução.',
      },
      menu: {
        servicos: {
          title: 'Serviços',
          desenvolvimentoDeSoftwares: 'Desenvolvimento de Softwares',
          bussinessInteligence: 'Business Intelligence',
          governanca: 'Governança de T.I',
          ti: 'TI',
        },
        treinamentos: 'Treinamentos',
        trabalheConosco: 'Trabalhe Conosco',
        covid19: 'COVID-19',
        contato: 'Contato',
      },
      home: {
        desenvolvemosSoftware: 'Desenvolvemos software,',
        naoImportaAPlataforma: 'não importa a plataforma,',
        nosEstamosProntos: 'nós estamos prontos',
        saibaMais: 'Saiba mais',
        getiCursos: 'Getinfo Cursos',
        getiTreinamentos: 'Treinamentos',
        descricao: `Invista em sua carreira e aperfeiçoe seu conhecimento, venha para o time Getinfo. Aqui nós temos o treinamento que você precisa para tirar sua certificação.`,
        saibaMais: 'Saiba mais',
        nossosClintes: 'Nossos Clientes',
        verListaCompleta: 'Ver lista completa',
        verListaReduzida: 'Ver lista reduzida',
        seuproblema: 'O seu problema é o nosso desafio.',
        criadaEm: `Fundada em 2009, a Getinfo é uma empresa voltada para encontrar soluções que vão ajudar você ou sua empresa a otimizar resultados.....`,
      },
      softwareDeveloper: {
        title: 'Desenvolvimento de Software',
        descricao:
          'Trata-se do processo de desenvolvimento de um produto de software, seja ele para web ou mobile. Você tem uma ideia e deseja por em prática? Não importa a plataforma, nós estamos prontos!',
        textButton:
          'Se você precisa desenvolver projeto para web, android ou iOS, fale conosco:',
      },
      faleConosco: {
        text: 'Contato',
      },
      clubeDeVantagens: {
        title: 'Clube de Vantagens',
        descricao:
          'A plataforma Clube de Vantagens permite aos usuários novas experiências no espaço digital. Através do site e aplicativo é possível gerenciar todos os descontos de maneira simples, rápida e aproveitar ainda mais as vantagens do clube.',
        emBreve: 'Em Breve',
      },
      clinicaCas: {
        title: 'Clínica CAS',
        descricao:
          'Plataforma desenvolvida para a Clínica de Anestesia de Salvador, no intuito de agilizar os processos internos na clínica e ter todos os dados das cirurgias documentados de forma digital.',
      },
      cartorioAminthas: {
        title: 'Cartório Aminthas Garcez',
        descricao:
          'O site do Cartório Aminthas Garcez dispõe de todos os serviços que o cartório oferece. Além de permitir ao usuário, efetuar consultas de registro, firma e segunda via de aviso. O site é responsivo e se adapta em telas de qualquer dispositivo, sendo ele desktop ou mobile.',
        saibaMais: 'Saiba Mais',
        verProjeto: 'Ver projeto completo em:',
        outrosProjetos: 'Outros projetos',
      },
      pajucara: {
        title: 'Pajuçara',
        descricao: `O Aplicativo Pajuçara reúne todo o conteúdo multiplataformas do Pajuçara Sistema de Comunicação e oferece o maior conteúdo digital de Alagoas. O app reúne informação e entretenimento, com conteúdo interativo e em tempo real e foi desenvolvido nativamente para os sistemas iOS e Android.`,
        saibaMais: 'Saiba Mais',
        veiculosDeComunicacao: {
          title: 'Veículos de Comunicação',
          descricao:
            'Canais de comunicação de informações via Rádio e TV, seguindo elevados padrões éticos e de credibilidade.',
        },
        tvPajucara: {
          title: 'TV Pajuçara',
          descricao:
            'Com o smartphone, é possível assistir a toda uma programação de canais disponíveis na TV Pajuçara.',
        },
        radios: {
          title: 'Rádio Arapiraca',
          radioarapiracadescricao:
            'Programações das rádio Pajuçara FM Arapiraca 101,9.',
          avaliacoes: 'Avaliações',
          avaliacoesdescricao:
            'Programações das rádio Pajuçara FM Maceió 103,7.',
        },
        vocereporter: {
          title: 'Você Reporter',
          descricao:
            'Canal instantâneo e fácil para os usuários colaborarem com a Pscom via smartphone.',
        },
        portalDeNoticias: {
          title: 'Portal de Notícias',
          descricao:
            'Portal líder em acessos de Alagoas, informações seguindo elevados padrões éticos e de credibilidade.',
        },
      },
      pionet: {
        title: 'PioNet',
        descricao: `Sempre buscando avançar no tocante a tecnologia, a faculdade Pio Décimo disponibiliza o PioNET Mobile para Android e iOS. Esse app disponibiliza para os alunos da faculdade o acesso à: notas e faltas, horários, avisos e pendências.`,
        saibaMais: 'Saiba Mais',
        app: 'App PioNet',
        appDescricao:
          'Os alunos da faculdade Pio Décimo agora possuem um aplicativo para poder visualizar horários, notas, faltas, pendências e avisos.',
        horario: 'Horário',
        horarioDescricao:
          'É possível visualizar toda a grade do período corrente do aluno. Informando os dias e horários em que o aluno tem determinada aula.',
        notasefaltas: 'Notas e Faltas',
        notasefaltasDescricao:
          'Com o smartphone, é possível saber quais foram as faltas e notas do período corrente do aluno.',
        pendencias: 'Pendências',
        pendenciasDescricao:
          'É possível checar se há alguma pendência por parte do aluno com a instituição.',
        avisos: 'Avisos',
        avisosDescricao:
          'Assim que o docente posta um aviso, o discente recebe uma notificação. De forma rápida e prática, sem complicações.',
      },
      getiTreinamentos: {
        title: 'Getiinfo Treinamentos',
        descricao:
          'Getinfo Treinamentos faz você relembrar seu conteúdo estudado através de questionários direcionados. Simule provas de certificações importantes, quebre a cabeça lembrando dos assuntos do seu dia a dia profissional ou então saiba quais assuntos são necessários para se aprofundar no conhecimento que você deseja.',
        saibaMais: 'Saiba Mais',
        crieumaconta: 'Crie uma conta e salve seu progresso.',
        crieumacontadescricao: `Através de seu e-mail ou sua conta do Facebook, cadastre-se e
                tenha acesso ao seu histórico de simulados, acompanhando assim,
                o seu progresso.`,
        controleotempo: 'Controle o tempo',
        controleotempodescricao: `Os simulados apresentam situações parecidas com as provas
                originais. Cronometre seu tempo e identifique as questões que
                você tenha mais dificuldade.`,
        simuladoItil: 'Simulado ITIL Foundation disponível!',
        simuladoItildescricao: `ITIL Foundation é um dos testes de certificação mais importantes
                da área de Tecnologia da Informação, abrangendo conhecimentos
                sobre estratégia, design, operações e desenvolvimento de
                serviços.`,
        baixeagora: 'Baixe agora e teste seus conhecimentos.',
        baixeGratuito: 'Baixe gratuitamente',
      },
      footer: {
        servicos: {
          title: 'Serviços',
          desenvolvimentoDeSoftware: 'Desenvolvimento de Softwares',
          bussinessInteligence: 'Business Intelligence (BI)',
          governanca: 'Governança de TI',
          thinkAgile: 'Thinking Agile (Gestão Ágil)',
          treinamentos: 'Treinamentos',
          contato: 'Contato',
          sede: 'Sede',
        },
        copyright:
          '© Copyright 2021 | Todos os direitos reservados | Design e desenvolvimento por Getinfo.',
        telefone: 'Telefone',
        numeroTelefonico: '(79)3512-2319',
      },
      404: {
        paginanaoencontrada: 'Página não encontrada...',
        paginanaoexiste:
          'A página solicitada não existe ou está em construção.',
        voltaraoinicio: 'Voltar para a página inicial',
      },
      blog: {
        carregandonoticias: 'Carregando notícias...',
        semnoticias: 'Nenhuma notícia publicada até o momento.',
      },
      blogPost: {
        carregandonoticias: 'Carregando notícias...',
        naofoipossivelcarregarasnoticias:
          'Não foi possível carregar a notícia, certifique que a URL está correta',
      },
      bussiness: {
        servicos: {
          title: 'Business Intelligence',
          descricao: `A plataforma de Business Discovery Qlik é uma solução de BI
                orientada pelo usuário, que permite a tomada de decisões de
                forma rápida e inteligente. Inclui fazer coisas como:`,
          desenvolvimentoDeIndicadores:
            'Desenvolvimento de indicadores-chave de desempenho (KPIs);',
          analiseDeTendencias: 'Análise de tendências;',
          modelagemPreditiva: 'Modelagem preditiva;',
          paineis: 'Painéis',
          visaoGeraldaFerramenta: 'Visão geral da ferramenta',
        },
        // Kpi Dashboard
        imagem: {
          title: 'Decisões precisas e com economia de tempo',
          text: `A tomada de decisão eficaz baseia-se em ter a informação certa disponível e de fácil acesso.
        Levando apenas alguns minutos para aprender, as associações automáticas do Qlik criam
        infinitas possibilidades de fazer consultas sem exigir estruturas e hierarquias tediosas,
        como é típico em outras ferramentas de análise de dados.`,
        },
        // Sales analisys
        imagem2: {
          title: 'Entenda como os dados estão associados',
          text: `O Qlik permite que os usuários obtenham informações inesperadas do negócio ao entender
                    como os dados estão associados e quais dados não estão relacionados.`,
        },
        // Interfaces de Usuario
        imagem3: {
          title: 'Interfaces de usuário simples e diretas',
          text: `Ele traz um novo nível de análise, insight e valor para os armazenamentos de dados existentes com interfaces de usuário que são limpas, simples e diretas.`,
        },
        //Integrações com todas as bases de dados
        imagem4: {
          title: 'Integrações com todas as bases de dados',
          text: `Unifique fontes de dados para uma visão completa de informações, sem intervalos, e torne mais fácil descobrir insights ocultos.`,
        },
        servico: {
          casosdesucesso: 'Casos de sucesso',
          banese: `Aplicação que permite aos gestores ter uma visão detalhada
                      do perfil dos seus clientes e também analisar as
                      tendências relacionadas ao seu comportamento. Alem disso,
                      é possível acompanhar as transações realizadas nos canais
                      de atendimento, como também, avaliar indicadores da
                      carteira de crédito, tais como a quantidade de contratos
                      existentes, valores liquidados, dentre outros.`,
          pscom: `Painel elaborado para acompanhar a situação geral das
                      vendas de espaços publicitários dos veículos do grupo
                      Pajuçara, assim como avaliar a performance da equipe de
                      vendas.`,
          progresso: `MKBF (Mean Kilometer Between Failure) - Aplicação para o acompanhamento da quantidade de quilômetros que um carro
                      pode andar até apresentar uma falha, além de apresentar as
                      linhas em que os ônibus quebram mais, quais motoristas dão
                      mais entradas nas oficinas.`,
          progressoPt2: `Previsão da arrecadação mensal – Painel que emite uma
                      projeção da arrecadação mensal baseada nos dias do mês que
                      já possuem os dados.`,
          faleConosco: {
            textButton:
              'Fale Se você gostaria de ter essa solução, fale conosco para mais detalhes:',
          },
        },
      },
      governanca: {
        title: 'Governaça de T.I',
        descricao:
          'Estamos aptos a promover e garantir a segurança das informações, disponibilidade dos serviços e continuidade de negócios, fornecendo serviços com base nas melhores práticas do mercado.',
        destaqueSeusNegocios: 'Destaque seus negócios',
        destaqueDescricao: `Você que procura dar destaque ao seu negócio e chegar na frente na corrida empresarial, aposte nas ferramentas de TI. Elas auxiliam no processo de gestão e controle, além de garantir a qualidade dos serviços e produtos ofertados.`,
        aumenteACompetitividade: 'Aumente a competitividade de sua empresa',
        competitividadeDescricao: `Agora ficou ainda mais fácil idealizar estratégias para
                  aumentar sua competitividade: Por meio de um conjunto de
                  práticas que utilizam softwares e sistemas que se adequam aos
                  objetivos e regras da sua organização. Conheça já como a
                  Governança de TI pode ser utilizada em sua empresa e alcance
                  excelentes resultados para o seu negócio.`,
        suporteTecnico: 'Suporte técnico especializado',
        suporteTecnicoDescricao: `Propomos soluções completas e customizadas de telefonia,
                  armazenamento de dados, gerenciamento e monitoramento do
                  parque de servidores e estações. Contamos com suporte técnico
                  espacializado, com monitoramento de Infraestrutura e Support By Report.`,
        textButton:
          'Se você gostaria de ter essa solução, fale conosco para mais detalhes:',
      },
      treinamentos: {
        title: 'Treinamentos',
        descricao: `Invista em sua carreira e aperfeiçoe seu conhecimento, venha para o time Getinfo. Aqui nós temos o treinamento que você precisa para tirar sua certificação.`,
        agile: {
          title: 'Treinamento - THINKING AGILE (GESTÃO ÁGIL)',
          descricao:
            'Você já utiliza um modelo de Gestão Ágil para conceber seus projetos em interações curtas, entregas contínuas com um time multifuncional? Se a sua resposta foi não, a Get_info pode te ajudar.',
          saibaMais: 'Saiba Mais',
        },
        aplicativo: {
          title: 'Conheça o aplicativo de treinamentos',
          saibaMais: 'Saiba Mais',
        },
        treinamentoAgile: {
          title: 'Treinamento - THINKING AGILE (GESTÃO ÁGIL)',
          descricao: `Você já utiliza um modelo de Gestão Ágil para conceber seus projetos em interações curtas, entregas contínuas com um time multifuncional? Se a sua resposta foi não, a Get_info pode te ajudar.`,
          dataTurma:
            'Devido à pandemia do COVID 19 estamos adaptando nosso treinamento para a versão on-line e, posteriormente, divulgaremos as novas datas.',
          investimento:
            'R$690,00 / R$600,00 (Boleto bancário) / 10% de Desconto (Cartão 1X) / Inscrições em grupo tem desconto.',
          horario: '8h – 12h e 14h – 18h.',
          instrutorNome: 'Adriana Fontes',
          instrutorDescricao: `Adriana Fontes é mestra em Ciência da Computação pela Universidade
              Federal de Sergipe, possui MBA em Gerência de Projetos cursado na Faculdade de
              Administração de Negócios de Sergipe (FANESE) e especializações em Gestão de
              Projetos de Software na FANESE e Didática do Ensino Superior na Associação de
              Ensino e Cultura Pio Décimo. Cursou Bacharelado em Sistemas de Informação na
              Universidade Tiradentes e Técnico em Informática com Ênfase em Desenvolvimento
              de Sistemas no Colégio Pio Décimo.
              É Certified Scrum Professional - ScrumMaster, Certified Scrum Professional - Product Owner e Certified Lean Kanban pela LeanKanban Universit.
              Exerceu os cargos e funções de Programadora de Sistemas, Analista de Sistemas, Product Owner e Scrum Master em diversos projetos na Associação de Ensino e Cultura Pio Décimo
              por quase 10 anos. Coordenou e lecionou diversas disciplinas nos cursos de Graduação em Pedagogia e Técnico em Informática na Faculdade e no Colégio Pio Décimo respectivamente.
              Lecionou no curso Bacharelado em Sistemas de Informação no Instituto Federal de Sergipe - Campus Lagarto. Coordenou o curso de Tecnologia em Defesa Cibernética na Faculdade UNIRB -
              Campus Aracaju. Atuou como Consultora, Gerente de Projetos e Scrum Master na empresa Trust Consultoria e Softwares por 8 anos. Em 2019 fez 8 meses de intercâmbio no Canadá onde estudou inglês na
              International Language Academy of Canada em Toronto. E, Atualmente, atua como Consultora na empresa Get_Info Soluções Corporativas.`,
          objetivo: `Nosso treinamento Thinking Agile contempla técnicas e métodos do
              Design Thinking para te ajudar a materializar sua ideia e, em seguida,
              o Framework Scrum somado ao conceito de Squads para desenvolver
              e colocar no mercado seu produto ou serviço.`,
          publicoAlvo: 'Estudantes, gestores e profissionais em geral.',
          conteudoProgramatico:
            'Cultura Ágil e Gestão Ágil de Projetos.\nManifesto Ágil, Princípios e Valores do Manifesto Ágil.\nFramework Scrum:\n   Time   Scrum   -   Product   Owner,   Scrum   Master, Time   de  Desenvolvimento.\n Eventos do Scrum - Sprint, Sprint Planning, Daily Scrum, Sprint  Review, Sprint Retrospective, Release.\n Artefatos do Scrum - Product Backlog, Sprint Backlog, Definição de Pronto, Incremento  do  Produto, Metas de Negócios, Gráficos de Acompanhamento do Trabalho, Definição de Preparado.\nMaterializando a Visão do Produto.\nTécnicas e Métodos do Design Thinking.\nSquads, Tribos, Chapter.\nCertificações da Scrum Alliance.\nTreinamento   com   diversas   dinâmicas   e   práticas   que   objetivam aumentar  o engajamento dos participantes e a assimilação do conteúdo.',
          datasETurmasTexto: 'Datas e Turmas',
          investimentoTexto: 'Investimento',
          horarioTexto: 'Horário',
          informacoesTexto: 'Informações',
          contatoTexto: 'Entre em contato através do email',
          instrutorTexto: 'Instrutor',
          objetivoTexto: 'Objetivo',
          preRequisitoTexto: 'Pré-Requisitos',
          publicoAlvoTexto: 'Público Alvo',
          conteudoProgramaticoTexto: 'Conteúdo Programático',
          inscrevaSeTexto: 'Inscreva-se',
          inscricoesEncerradasTexto: 'Inscrições Encerradas',
        },
      },
      trabalheConosco: {
        envieSeuCurriculum: 'Envie seu currículo',
        preenchaSeusDados: 'Preencha seus dados abaixo',
        nome: 'Nome *',
        area: 'Área *',
        email: 'E-mail *',
        selecioneUmaArea: 'Selecione uma área',
        administrativo: 'Adminsitrativo',
        comercial: 'Comercial',
        desenvolvimento: 'Desenvolvimento',
        estagio: 'Estágio',
        suporte: 'Suporte',
        celular: 'Telefone Celular *',
        selecioneOArquivo: 'Selecione o arquivo',
        enviar: 'Enviar',
      },
      contato: {
        campoObrigatorio: 'Campo obrigatório',
        recaptcha: 'Efetue a verificação do robô.',
        envienoumamensagem: 'Envie-nos uma mensagem',
        nome: 'Nome',
        placeName: 'Digite seu nome',
        email: 'E-mail',
        placeEmail: 'Digite seu e-mail',
        telefone: 'Telefone',
        placePhone: '(99)99999-9999',
        assunto: 'Assunto',
        placeSubject: 'Digite o assunto',
        mensagem: 'Mensagem',
        placeMessage: 'Digite sua mensagem',
        sendButton: 'Enviar',
        comoChegar: 'Como chegar até nós:',
        endereco: 'Endereço:',
        telefone: 'Telefone:',
        telephoneNumber: '(79)3512-2319',
      },
    },
  },
};
